import React from 'react';
import Table from '../lists/Table';
import Section from '../panels/Section';
import { formatPercentage } from '../../lib/utils';
import { connect } from 'react-redux';

const PanelPercentagemContatosAgenda = ({ summary, session }) => {
  if (!summary || !session || !session.user) {
    return null;
  }

  if (!summary.ResumosContatoAgenda) {
    return null;
  }

  const currentUser = session.user;
  const tableRows = summary.ResumosContatoAgenda.map(el => {
    let value = 0;
    // Vendedores
    if (session.viewAs && currentUser.Perfil === 1 && el.no_qtd_contatos > 0) {
      value = el.no_qtd_efetuados / el.no_qtd_contatos;
    }

    // Gerentes e Supervisores
    if (!session.viewAs && currentUser.Perfil >= 2 && el.no_qtd_contatos_GerSup > 0) {
      value = el.no_qtd_efetuados_GerSup / el.no_qtd_contatos_GerSup;
    }

    return [el.tx_agenda, formatPercentage(value)];
  });

  const sortedTableRows = tableRows.sort((a1, a2) => (a1[0] > a2[0] ? 1 : -1));
  const table = <Table rows={sortedTableRows} />;
  return (
    <Section
      title="% de Contatos X Agenda"
      content={table}
      collapsable={true}
    />
  );
};

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps)(PanelPercentagemContatosAgenda);
